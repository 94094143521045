.delivery-address-focus-visible:focus-visible,  {
    outline: 0;
    box-shadow: 0 0 10px 2px $secondary-03;
}

body.settings {
    @include until($screen-phone) {
        padding: 50px 20px 0 20px;
    }
    
    .form-group {
        margin-bottom: $spacing-m;

        input {
            margin-bottom: 0;
        }
        
        .hint-text {
            font-size: 13px;
            margin: $spacing-xxs 0;
        }
    }

    .sr-only {
        color: transparent;
    }
    h1 {
        margin-bottom: .5em;
        hyphens: auto;
    }

    .section {
        position: relative;
        border-top: 2px solid rgba(255,255,255,0.7);
        box-shadow: 0 -1px 0 0 rgba(0, 0, 0, 0.05);
        padding-top: 15px;
        margin-bottom: 30px;
        line-height: 1.6;

        .account-credit-summary {
            margin: auto;
            text-align: left;
            width: 100%;
            max-width: ($scss-container-max-width / 12) * 8;
            margin-bottom: 20px;

            td {
                padding: 10px 15px;
            }

            tbody {
                tr:nth-child(odd) {
                    border-top: rgb(244, 244, 244) solid 1px;
                    border-bottom: rgb(244, 244, 244) solid 1px;
                }
                td {
                    &:nth-child(2) {
                        padding-left: 30px;
                        text-align: right;
                        text-transform: uppercase;
                    }
                }
            }
        }

        p {
            background-color: rgba(255, 255, 255, 0.7);
            padding-left: 0;
            border-radius: 4px;
            > span {
                vertical-align: middle;
            }
        }
        h5 {
            margin-bottom: 20px;
            padding-right: 50px;
            > span {
                vertical-align: middle;
            }
        }
        label {
            font-weight: bold;
            text-transform: none;
            font-size: $font-size-body;
        }
        .edit {
            &:before {
                color: $secondary-03;
            }
        }
        &.you {
            box-shadow: none;
            border-top: none;
        }
        .refer {
            display: inline-flex;
        }
    }
}

body.settings-address {
    .form-group {
        width: 100%;
        .input-wrapper {
            width: 100%;
            .postcode-lookup {
                position: unset;
            }
        }

        label {
            display: flex;
        }
    }
    ul.checklist {
        li.feature-row {
            display: none;
            margin-top: -10px;
            font-weight: normal;
        }

        ul.features {
            li {
                display: list-item;
                list-style-type: disc;
                background: none;
                margin: 0;
                padding-bottom: 0;
                font-weight: normal;

                p {
                    margin: 0;
                }
            }
        }

        .view-more {
            display: none;
        }

        p.smallprint {
            margin: 0;
            margin-top: 10px;
            font-style: italic;
        }
    }

    ul.checklist.delivery-services {
        input[type=radio]:checked+label:before,
        input[type=radio]+label:before {
            margin-left: 10px;
            margin-bottom: 8px;
        }
        li {
            position: relative;

            .expand-trigger {
                cursor: pointer;

                &:after {
                    @include icon($icon-arrow-down-circle);
                    font-size: $font-size-l;
                    position: absolute;
                    top: 50%;
                    margin-top: -(40px / 2);
                    right: 20px;
                    @include transition(transform 200ms);
                    color: $navy-01;
                }

                &:focus-within::after {
                    outline: 0;
                    box-shadow: 0 0 10px 2px $secondary-03;
                }

                &.flip:after {
                    @include transform(rotate(180deg));
                }
            }
        }
    }
    .delivery-window {
        > select {
            width: 320px;
        }
    }
    .tails-view-alerts {
        display: none;
    }
    .collection-points-list {
        .map {
            margin-left: 0px;
            margin-right: 0px;
        }
    }
}

body.settings-user {
    .mobile {
        @include flexbox(row, baseline, flex-start);
        width: 100%;
        .plus {
            font-size: 24px;
            font-weight: bold;
            margin-top: $spacing-xxs;
        }
        .calling-code {
            max-width: 3em;
            padding-right: 0;
            margin-right: 5px;
            margin-left: 5px;
            text-align: left;
        }
        .number {
            @include flex-grow(1);
        }
    }
}

body.settings-communication {

    .required {
        &:before, &:after {
            display: inline-block;
            color: $product-error;
            margin: 0 5px;
        }
        &.before:before {
            content: "*";
        }
        &.after:after {
            content: "*";
        }
    }

    .comms-categories {
        @include flexbox(column, centre, centre);
        margin-bottom: 32px;
        .category {
            margin: 20px 0;
            @include flexbox(column, flex-start, center);

            text-align: left;
            .validation-message {
                display: none;
            }

        }
        h5 {
            color: $primary-black;
        }
    }

    .radio-buttons {
                position: relative;
                text-align: left;
                width: min-content;
                width: -moz-min-content;
                width: -webkit-min-content;
                white-space: nowrap;
                .radio-toggle-button {
                    margin-right: 20px;
                    margin: 0;
                    margin-right: 10px;
                    span {
                        text-transform: capitalize;
                        font-weight: 400;
                    }
                }
                input[type='radio'] {
                    display: inline-block;
                    opacity: 0;
                    position: absolute;
                    left: 50%;
                    bottom: 0;
                    @include size(1px);
                    &:checked + .radio-toggle-button label {
                        @include radio_toggle_on;
                    }
                }
            }
    .communication-option {
        color: $navy-01;
        margin: 20px 0;
        .description {
            display: inline-block;
            padding-left: 42px;
            font-size: $font-size-s;
        }
    }
    .contact-channels {
        margin-left: 12px;
    }
    .channels {
        @include flexbox(column, flex-start, space-between);
        .radio-buttons {
            flex: 1 0 50%;
            margin: 12px 0;
            > span {
                display: block;
                padding-bottom: 12px;
            }
        }
    }
    .essential-information {
        margin: 15px 0;
        background-color: $pastel-lighter-frost;
        padding: 40px;
        h5 {
            padding-bottom: 5px;
        }
        p {
            margin: 0;
        }
    }

    .change-password {
        max-width: 296px;

        .form-group {
            display: inline-block;
            width: 236px;
            @include at-least($screen-tablet) {
                width: 256px;
            }
        }

        button.toggle-visibility.icon {
            display: inline;
            position: relative;
            vertical-align: sub;
            width: fit-content;
            padding: 0 0 0 8px;
            z-index: 2;
            border: 0;
            background: transparent;
            cursor: pointer;
            font-size: 2.6rem;
            color: $navy-00;
            &:focus,
            &:focus-visible {
                outline: none;
            }
        }

        .buttons-wrapper-aligned {
            display: flex;
            place-content: space-evenly;
        }
    }
}

body.settings-gocardless {
    .local-details {
        display: none;
    }

    label[for="local-details-toggle"] {
        cursor: pointer;
        padding: 10px 0;
    }

    input#local-details-toggle {
        display: none;
        &:checked ~ .local-details {
            display: block;
        }
    }
    .form-control.address-info.hide {
        display: none;
    }
}

body.settings-paypal {
    .paypal-wrapper {
        @include flexbox(column, center, center);

        .info-row .paypal-logo {
            max-width: 100px;
        }

        h3 {
            margin: 20px 0px;
        }

        .billing-question {
            margin: 11px 0;
        }

        .radio-buttons {
            margin-top: 10px;
            text-align: left;

            .radio-toggle-button {
                margin: 0px;
                margin-right: 25px;
            }
        }

        .tails-view-address-lookup {
            margin-bottom: 0px;
        }

        .buttons-wrapper-aligned {
            text-align: left;

            @include until($screen-phone){
                display: flex;
                flex-flow: column wrap;
            }

        }

        .address-search-postcode {
            display: flex;
            flex-flow: column wrap;
        }
    }
    .paypal-details {
        input[name=address-use_delivery_address] {
            &#delivery-address-no:checked {
                ~ fieldset .address-search {
                    display: block;
                }

                ~ .form-group .radio-buttons label[for=delivery-address-no] {
                    @include radio_toggle_on;
                }
            }

            &#delivery-address-yes:checked {
                ~ .form-group .radio-buttons label[for=delivery-address-yes] {
                    @include radio_toggle_on;
                }
            }
        }

        .postcode-lookup-wrapper {
            margin: 10px 0px;
        }

        .form-group.address-picker {
            .input-wrapper {
                width: 100%;
                margin: 10px 0px 0px 0px;
            }
        }

        .info-row {
            @include flexbox(row, center, flex-start);
            p {
                padding-left: 30px;
            }
        }
        img {
            width: 100%;
            max-width: 150px;
        }
    }
}

body.settings-billing {
    .postcode-lookup-wrapper {
        margin-top: 10px;
    }
}
