@mixin with-tip {
    display: none !important;

    &:checked ~ .with-tip {
        color: $primary-text-link;

        .tip {
            opacity: 1;
            transform: scale(1);
            pointer-events: all;

            &.bottom {
                @include translated(0, 60px, 0);
            }
        }
    }

    ~ .with-tip {
        vertical-align: middle;
        color: $primary-text-link;

        &.info {
            .anchor {
                cursor: pointer;
                color: $navy-01;

                &:before {
                    @include icon($icon-info-circle);
                }
            }
        }

        .tip {
            position: absolute;
            background-color: $primary-white;
            font-size: $font-size-s;
            border-radius: 4px;
            color: $navy-01;
            line-height: 1.4;
            z-index: 100;
            @include transition-property(transform, opacity);
            @include transition-duration(0.3s);
            transition-timing-function: cubic-bezier(0, 1.49, 0.56, 1.2);
            font-weight: normal;
            width: 98%;
            left: 2%;
            margin-top: -50px;
            opacity: 0;
            transform: scale(.1);
            overflow: hidden;
            border: 1px solid $pastel-lighter-frost;
            box-shadow: 0 3px 10px rgba(0, 0, 0, 0.05);
            pointer-events: none;
            white-space: normal;

            &.bottom {
                @include translated(0, 50%, 0);
            }

            h3 {
                text-align: center;
            }

            .tip-content {
                padding: 20px 40px;
            }

            .close {
                cursor: pointer;
                float: right;
                margin: 20px 20px 0 0;
                font-size: 3rem;
                color: $navy-04;
                opacity: 1;
                text-decoration: none;

                &:before {
                    @include icon($icon-close);
                }

                &:hover {
                    color: $secondary-03;
                }
            }
        }
        .icon {
            position: relative;
        }
        &:hover {
            color: $secondary-01;
        }
    }
}

.widget.social-links {
    .btn-share {
        padding: 15px;
        color: $primary-white;
        text-decoration: none !important;
        display: block;
        margin-bottom: 20px;
        font-size: 0;
        margin-left: 10px;
        margin-right: 10px;

        &:before {
            font-size: 3.6rem;
            @include inalign(middle);
        }

        span {
            @include inalign(middle);
            width: calc(100% - 56px);
            font-size: 2rem;
            font-variant: small-caps;
        }

        &.link {
            background: $primary-white;
            color: $navy-01;
            position: relative;
            min-height: 65px;
            perspective: 1000px;
            overflow: hidden;

            .share-link {
                @include position(absolute,0 0 0 0);
                @include flexbox(row,center,center);
                transition: transform 200ms ease;
            }

            .copy {
                background: $primary-white;
                transform: rotateX(0) translateZ(-30px);

                img {
                    margin-left: 25px;
                }
            }

            .copied {
                color: $primary-white;
                background: $secondary-01;
                transform: rotateX(90deg) translateZ(0);

                span:before {
                    @include icon($icon-check);
                    color: $primary-white;
                    margin: 0 10px;
                    font-size: 1em;
                    vertical-align: middle;
                }
            }

            &.copy-active {
                .copy {
                    transform: rotateX(90deg) translateZ(-35px);
                }
                .copied {
                    transform: rotateX(0) translateZ(-35px);
                }
            }

            input[name=promo-code] {
                position: absolute;
                opacity: 0;
            }

        }

        &.share-facebook {
            background-color: #3A5A99;

            &:before {
                @include icon($icon-facebook);
            }
        }

        &.twitter {
            background-color: #55ACEE;

            &:before {
                @include icon($icon-twitter-circle);
            }
        }

        &.email {
            background-color: #DC4B3E;

            &:before {
                @include icon($icon-envelope);
            }
        }

        &.whatsapp {
            background-color: #25D366;

            &:before {
                @include icon($icon-whatsapp);
            }
        }

        &.share-messenger {
            background-color: $primary-white;

            > span {
                white-space: normal;
                font-size: $font-size-s;
                font-weight: bold;
                color: $navy-01;
                line-height: 1;
            }

            > img {
                @include inalign(middle);
                width: 42px;
                margin: -4px 0;
            }
        }
    }

    @include at-least($screen-tablet) {
        @include flexbox(row);
        @include justify-content(space-between);
        @include align-items(center);
        @include flex-wrap(wrap);

        .btn-share {
            flex-grow: 1;
            margin: 0 10px 20px 10px;
            min-width: calc(50% - 30px);
        }
    }

    @include at-least($screen-desktop) {
        .btn-share {
            width: auto;
            min-width: calc(20% - 30px);
        }
    }
}

.postcard-widget {
    .slider {
        white-space: nowrap;
        overflow: hidden;

        .form, .sent {
            white-space: normal;
            @include inalign(middle);
            width: 100%;
            @include transform(translate(0%));
            @include transition(all 0.3s);
        }

        .form {
            input {
                margin-bottom: 10px;
            }
        }
    }

    &.sent {
        .form, .sent {
            @include transform(translate(-100%));
        }
    }
}

.discount-banner {
    padding: 0 25px;

    p {
        font-weight: bold;
        color: $secondary-01;
    }
}

.third-party-popup {
    width: calc(100% + 50px);
    margin: -25px -25px -40px;
    background-color: $primary-white;
    max-height: 90vh;
    overflow: auto;

    .hero {
        width: 100%;
    }

    .body {
        padding: 30px 15px 15px;
    }

    .btn {
        @include grid-blocks(3);
        display: inline-block;
        margin-bottom: 10px;
    }

    .terms {
        font-size: $font-size-s;
    }
}

.tails-view-multi-dog-slider {
    .slick-list {
        overflow: hidden;
        margin: 0 -30px;
    }

    .slide {
        @include inalign(top);
        outline: none;
    }

    .slick-slide {
        margin: 0 30px;
    }

    .slide-selector {
        @include flexbox(row, center, space-around);

        .dots {
            width: 100%;
            margin-bottom: 15px;

            .pets-name {
                font-size: 2.4rem;
                font-weight: bold;
                opacity: 1;
                @include transition(all 0.1s);
                transform: translate3d(0, 0, 0);

                &.fade {
                    opacity: 0;
                    transform: translate3d(0, -5px, 0);
                }
            }

            .slick-dots {
                margin: 0;
            }
        }

        .slick-arrow {
            color: $navy-01;
            text-align: center;
            cursor: pointer;
            width: 50px;
            height: 50px;
            line-height: 60px;

            &:before {
                font-size: 2.4rem;
            }
        }

        .slick-arrow.left {
            &:before {
                @include icon($icon-chevron-left);
            }
        }

        .slick-arrow.right {
            &:before {
                @include icon($icon-chevron-right);
            }
        }
    }
}

.g-exclusion-icons {
    .exclusion {
        @include inalign(middle);
        padding: 15px 5px 0;

        .icon {
            font-size: 3.6rem;
            color: $secondary-03;
        }

        &[data-ingredient=chicken] .icon:before {
            @include icon($icon-exclusion-chicken);
        }

        &[data-ingredient=beef] .icon:before {
            @include icon($icon-exclusion-beef);
        }

        &[data-ingredient=dairy] .icon:before {
            @include icon($icon-exclusion-dairy);
        }

        &[data-ingredient=wheat] .icon:before {
            @include icon($icon-exclusion-wheat);
        }

        &[data-ingredient=fish] .icon:before {
            @include icon($icon-exclusion-fish);
        }

        &[data-ingredient=egg] .icon:before {
            @include icon($icon-exclusion-egg);
        }

        &[data-ingredient=lamb] .icon:before {
            @include icon($icon-exclusion-lamb);
        }

        &[data-ingredient=maize] .icon:before {
            @include icon($icon-exclusion-maize);
        }

        &[data-ingredient=grain] .icon:before {
            @include icon($icon-exclusion-grain);
        }

        &[data-ingredient=soya] .icon:before {
            @include icon($icon-exclusion-soya);
        }

        .text {
            text-transform: capitalize;
        }
    }
}

.alert-window.single-product {
    @include grid-blocks(6);
    padding: 0;
    @include until($screen-phone) {
        @include position(absolute, 0 0 0 0);
        height: auto;
    }

    .body {
        @include flex-wrap(wrap);
        display: flex;
        position: relative;
        background: $primary-white;
        padding: 15px;
        overflow: auto;
        max-width: 100%;
        max-height: 75vh;
        @include until($screen-phone) {
            min-height: calc(100vh - 60px) !important;

            &.with-nav {
                min-height: calc(100vh - 130px) !important;
            }
        }
    }
}

.tails-view-multi-product-selector-nav {
    background-color: $primary-white;

    .mobile-header {
        display: none;
        @include until($screen-phone) {
            display: flex;
            width: 100%;
            height: 40px;
            justify-content: space-between;
            padding-top: 5px;
            padding-bottom: 5px;

            a {
                text-decoration: none;
                color: $primary-colour;
            }

            span{
                display: inline-flex;
                vertical-align: middle !important;
                line-height: 30px;

                &:before {
                    font-size: 2.4rem;
                    @include icon($icon-chevron-left);
                    padding-left: 8px;
                    padding-right: 5px;
                    vertical-align: middle;
                    line-height: 30px;
                }
            }
        }
    }
    .close-container {
        height: 30px;

        .close-btn {
            @include position(absolute, 0 0 null null);
            cursor: pointer;
            padding:5px;

            &:before {
                font-size: $font-size-m;
                @include icon($icon-cross-circle);
                color: rgba(0, 0, 0, 0.15);
            }
        }

        @include until($screen-phone) {
            display: none;
        }
    }
    .nav {
        @include flexbox(row, center, center);
        @include flex-wrap(wrap);

        ul {
            display: flex;
            justify-content: space-around;
            list-style: none;
            padding: 0 20px;
        }

        li {
            background-color: $pastel-lighter-frost;
            border: 10px solid $pastel-lighter-frost;
            color: darken($scss-pastel-lighter-frost, 20%);
            padding: 10px 65px;

            &:hover {
                cursor: pointer;
            }

            &.selected {
                background-color: $primary-white;
                color: $navy-01;
            }
        }

        @include until($screen-phone) {
            ul {
                padding: 0;
            }

            li {
                border: 6px solid $pastel-lighter-frost;
                padding: 10px 30px;
            }
        }
    }
    .free-holder {
        @include flexbox(row, center, center);
        width: calc(100% - 30px);
        background-color: $product-error;
        text-align: center;
        padding: 8px 15px;
        margin: 0 auto;

        img {
            width: 100px;
        }

        .text {
            flex-grow: 1;
            color: $primary-white;
            h5 {
                color: $primary-white;
            }
        }

        @include until($screen-phone) {
            width: 100%;
        }
    }
}

.tails-view-single-product-widget {
    label[for=see-more] {
        cursor: pointer;
        color: $primary-text-link;
        text-decoration: underline;
        width: 100%;

        &:before {
            content: attr(data-show);
        }
    }
    .see-more {
        display: none;
        width: 100%;
        padding: 15px;
        background-color: $pastel-lighter-frost;
        margin-bottom: 10px;
        margin-top: 40px;
        border-radius: 4px;
        text-align: left;
    }
    #see-more {
        display: none;

        &:checked ~ .see-more{
            display: block;
            @include until($screen-phone) {
                margin-top: 20px;
            }
        }
        &:checked ~ label[for=see-more]:before {
            content: attr(data-hide);
        }
    }
    .warning {
        background: rgba(252, 161, 61, 0.15);
        padding: 15px;
        width: 100%;
        margin-top: 40px;
        border-radius: 4px;

        .message,
        .text {
            font-size: 17px;
        }

        @include until($screen-phone) {
            margin-top: 20px;
        }
    }
}

.tails-view-single-product-widget, .tails-view-wet-trial-upsell-modal {
    background-color: $primary-white;
    text-align: left;

    p {
        margin: 1rem 0;
    }

    .title {
        width: 100%;
        height: auto;
        text-align: left;

        h2 {
            padding: 0;
            margin: 0 0 15px 0;
        }

        p:last-child {
            margin-bottom: 0;
        }
    }
    .subtitle {
        @include flexbox(row, center, center);
        @include flex-wrap(wrap);
        display: flex;
        width: 100%;
        height: auto;
        text-align: left;
        text-transform: lowercase;
        font-size: $font-size-body;

        @include until($screen-phone) {
            font-size: $font-size-s;
        }
    }
    .recommendation {
        margin: 0 auto;
        width: 100%;

        @include until($screen-phone) {
            font-size: $font-size-s;
        }
    }
    .promo {
        background: $secondary-03;
        color: $primary-colour;
    }

    .wide-wrapper {
        background-color: $pastel-lighter-frost;
    }

    .price-display {
        margin-bottom: 1rem;
    }

    .product-features li {
        margin-bottom: 1rem;
    }

    .table {
        border: none;
        border-bottom: 1px solid $navy-01;
        margin: 2rem 0;

        tr {
            border-top: 1px solid $navy-01;

            td:last-child {
                text-align: right;
            }
        }
    }

    @include until($screen-phone) {
        .price-display {
            display: none;
        }
    }

    [data-fader="true"] {
        visibility: hidden;
        @include until($screen-phone) {
            z-index: 1;
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(255, 255, 255, 0.75);
        }
    }

    [data-size-popup="true"] {
        overflow-y: hidden;
        transition-property: all;
        transition-duration: 1s;
        transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
        visibility: hidden;
        max-height: 0;

        @include until($screen-phone) {
            z-index: 2;
            position: fixed;
            width: 100%;
            bottom: 0;
            background-color: $pastel-lighter-frost;
            height: 40%;

            .variant-sizes {
                margin: auto;
                padding-top: 25px;
                @include grid-blocks(3);

                .size {
                    flex: 1;
                    border: 1px solid $primary-colour;
                    text-decoration: none;
                    text-align: left;
                    color: $primary-colour;
                    border-radius: 5px;
                    padding: 1.5rem;
                    background: $primary-white;
                    cursor: pointer;
                    margin: auto;
                    margin-top: 10px;

                    &.selected {
                        background: $secondary-02;
                    }
                }
            }
        }
    }

    .product-description {
        width: 100%;
        display: flex;
        max-width: 800px;
        margin: 0 auto;

        .image-wrapper {
            flex: 1;
            background: $primary-colour-10;
            align-items: center;
            justify-content: center;
            text-align: center;
            @include grid-blocks(4);

            .product-image {
                background-repeat: no-repeat;
                background-size: contain;
                background-position: center;
                padding: 15px;
                background-origin: content-box;
                height: 380px;
            }

            .secondary-images {
                margin:auto;
                padding-top: 15px;

                .secondary-product-image {
                    background-color: rgba(255, 255, 255, 0.4);
                    margin: 0 5px 15px 5px;
                    cursor: pointer;
                    max-height: 70px;

                    &.active {
                        background-color: $primary-white;
                    }
                }
            }
        }

        .price-display {
            display: none;
        }

        @include until($screen-phone) {
            .price-display {
                display: block;
                text-align: center;
                background-color: $primary-white;
                padding: 3rem;
            }
        }

        .actions-wrapper {
            flex: 2;
            display: inline-block;
            background-color: $pastel-lighter-frost;
            @include grid-blocks(4);
            padding: 15px;

            .recommendation {
                padding: 1rem;
                margin: 1rem 0;
                background: $primary-white;
                border-radius: 5px;
                width: auto;
                display: flex;
                align-items: center;

                .roundal {
                    display: inline-block;
                    margin-right: 1rem;
                }
            }

            .size-selection {
                display: none;

                @include until($screen-phone) {
                    display: block;
                    border: 1px solid $primary-colour;
                    color: $primary-colour;
                    border-radius: 10px;
                    padding: 1rem 1rem 1rem 2rem;
                    background: $primary-white;
                    cursor: pointer;

                    &:after {
                        @include icon($icon-chevron-right);
                        float: right;
                        font-size: x-large;
                    }
                }
            }

            .desktop-sizes {
                @include until($screen-phone) {
                    display: none;
                }

                .variant-sizes {
                    margin: 2rem 0 3rem;
                    display: flex;
                    width: 100%;

                    .size {
                        flex: 1;
                        border: 1px solid $primary-colour;
                        text-decoration: none;
                        text-align: center;
                        color: $primary-colour;
                        border-radius: 5px;
                        margin-right: 1rem;
                        padding: 1rem 0;
                        background: $primary-white;
                        cursor: pointer;

                        &.selected {
                            background: $pastel-lighter-lavender;
                        }

                        &:last-child {
                            margin-right: 0;
                        }
                    }
                }
            }

            .actions {
                display: block;

                .counter {
                    min-width: 200px;
                }

                .save {
                    min-width: 200px;
                }

                div {
                    margin-top: 1rem;
                }
            }

            .actions-x-for-y {
                @include at-least($screen-phone) {
                    margin-top: 15px;
                    margin-bottom: 15px;
                }
            }
        }
        @include until($screen-phone) {
            display: block;
            padding: 0;
            margin: 0;

            .image-wrapper {
                width: 100%;
            }

            .actions-wrapper {
                width: 100%;
                margin: 2rem 0;

                .recommendation {
                    width: 100%;
                }

                .actions {
                    position: relative;

                    .counter {
                        width: 100%;
                    }

                    .save {
                        width: 100%;
                    }
                }
            }
        }
    }
}

.alert-window.single-product {
    .tails-view-wet-trial-upsell-modal {
        padding: 20px;

        .body {
            @include flexbox(row, flex-start, flex-start);
            @include at-least($screen-tablet) {
                @include flexbox(column, stretch, flex-start);
            }
            padding: 0;

            .title {
                display: block;

                .first-order {
                    color: $secondary-01;
                    font-weight: bold;
                    text-align: center;
                }
            }
            .product-description {
                @include flexbox(column, center, space-around);
                @include at-least($screen-tablet) {
                    @include flexbox(row, center, space-around);
                }
                width: 100%;

                .image-wrapper {
                    width: 100%;
                    @include at-least($screen-tablet) {
                        width: 50%;
                    }

                    .price-roundal {
                        @include flexbox(column, center, center);
                        bottom: 50px;
                        right: 40px;

                        p {
                            font-size: $font-size-body;
                            margin: 0;
                        }

                        .price {
                            font-weight: bold;
                        }

                        .strikethrough {
                            text-decoration: line-through;
                        }
                    }
                    .ongoing-price {
                        font-size: 1.4rem;
                        font-weight: 400;
                    }
                }
                .quantity {
                    text-align: center;
                    @include at-least($screen-tablet) {
                        text-align: left;
                    }

                    p {
                        margin: 0 0 5px 0;
                    }

                    .actions-wet-trial {
                        &.offer {
                            @include at-least($screen-tablet) {
                                @include flexbox(row, stretch, flex-start);
                            }
                            @include flexbox(row, stretch, center);
                            margin-top: 10px;
                            margin-bottom: 20px;
                            border: none;

                            .add {
                                &:after {
                                    @include icon($icon-plus);
                                }
                            }

                            .remove {
                                &:before {
                                    @include icon($icon-minus-circle);
                                }
                            }

                            &[data-status=taken] {
                                .btn.add {
                                    display: none;
                                }
                                .btn.remove {
                                    display: block;
                                }
                            }
                        }
                    }
                }
            }
            .product-features {
                ul {
                    margin: 0;
                    padding: 0;
                    list-style: none;

                    li {
                        font-size: 0.9em;
                        font-weight: 400;
                        background-color: $pastel-lighter-frost;
                        text-align: left;
                        line-height: 1.3;
                        margin: 0 0 3px 0;
                        padding: 10px 10px 10px 40px;

                        &:before {
                            @include icon($icon-check);
                            color: $secondary-01;
                            margin-left: -25px;
                            position: absolute;
                        }
                    }
                }
            }
        }
    }
}

.widget-increment-decrement.counter {
    display: inline-block;
    height: 100%;
    border-radius: 50px;
    vertical-align: middle;
    background: $primary-white;
    border: 1px solid $navy-01;
    text-align: center;

    * {
        display: inline-block;
        vertical-align: middle;
    }

    .btn {
        padding: 0;
        min-width: 30px;
        min-height: 30px !important;

        span {
            margin-left: 0 !important;
            line-height: 30px;
            font-size: 2.4rem;
            width: auto;
            color: $primary-white;
        }

        &.plusminus {
            top: 7px;
            background: $primary-white;

            .icon {
                font-size: $font-size-body;
                color: $navy-01;
            }
        }
        &.minus {
            float: left;
            margin-left: 5px;
        }
        &.plus {
            float: right;
            margin-right: 5px;
        }
    }

    input, input[type="text"] {
        height: 30px;
        padding: 0;
        color: $navy-01;
        background-color: $primary-white;
        text-align: center;
        width: 7em;
        font-size: $font-size-s;
        outline: none;
        border: none;
        margin-bottom: $spacing-xxs;
    }

    input:focus, input[type="text"]:focus {
        box-shadow: none;
    }

    input:invalid, input[type="text"]:invalid {
        border-bottom-color: $product-error;
    }
}

.tip-input {
    @include with-tip;
}

.tails-view-exclusions {
    .toggle-buttons {
        @include grid-blocks(3);

        .toggle-button {
            width: 50%;
        }
    }

    #exclusions-yes:checked ~ .radio-buttons #exclusions-yes-label,
    #exclusions-no:checked ~ .radio-buttons #exclusions-no-label {
        @include radio_toggle_on;
    }

    #exclusions-yes:checked ~ .choices {
        display: block;
    }

    .choices {
        margin-top: 30px;
        display: none;
    }

    .choices-shown {
        margin-top: 30px;
        .allergen-info {
            &:before {
                @include icon($icon-information);
            }
        }
    }

    .checklist {
        li {
            .text {
                width: 100%;
            }

            .icon {
                @include inalign(middle);
                font-size: $font-size-l;
            }
        }

        &.allergens {
            word-spacing: 10px;

            li {
                width: 100%;
                @media screen and (min-width: $screen-tablet) {
                    display: inline-block;
                    width: 48%;
                    margin-bottom: 0;
                    width: calc(50% - 8px);
                }

                label:before {
                    content: $icon-times;
                }

                input:checked ~ label:before {
                    content: $icon-times;
                    background-color: $product-error;
                    border-color: $product-error;
                }
            }
        }
        &.categories {
            position: relative;
        }
    }

    .tip-input {
        @include with-tip;
    }

    label[for="hypoallergenic-help"] {
        color: $secondary-03;

        .tip {
            max-width: 640px;
            right: 0;
            left: auto;
        }
    }
}

.card-details {
    padding-top: 50px;
    position: relative;

    &.has-error {
        @include error-border;
    }

    .stripe-input {
        @include input_style(0, 1px solid $navy-01);
        position: relative;

        &#card-number {
            margin-right: 58px;

            &:after {
                content: '';
                width: 42px;
                height: 24px;
                background-position: center center;
                background-size: cover;
                position: absolute;
                right: -58px;
                top: 12px;
            }

            &.visa:after {
                background-image: url('/static/images/payment-icons/visa-128px.png');
            }

            &.mastercard:after {
                background-image: url('/static/images/payment-icons/mastercard-128px.png');
            }

            &.amex:after {
                background-image: url('/static/images/payment-icons/american-express-128px.png');
            }
            &.direct-debit:after {
                background-image: url("/static/images/payment-icons/direct-debit-128px.png");
            }
            &.sepa-lastschrift:after {
                background-image: url("/static/images/payment-icons/sepa-lastschrift-128px.png");
            }
            &.maestro:after {
                background-image: url('/static/images/payment-icons/maestro-128px.png');
            }
        }
    }
    .hint-text {
        font-size: 13px;
        margin: $spacing-xxs 0;
    }
    .expiry-cvc {
        display: flex;
        width: 100%;

        #card-expiry-label {
            padding-right: 16px;
        }

        #card-expiry {
            width: 76px;
            margin-bottom: 0;
            margin-right: $spacing-m;
        }

        #card-cvc {
            max-width: 64px;
            margin-bottom: 0;
        }

        @media screen and (max-width: $screen-phone) {
            #card-expiry-label, #card-cvc-label {
                min-height: 40px;
            }
        }
    }

    .alert-danger {
        background-color: $product-error;
        color: $primary-white;
        border: none;
        border-radius: 4px;
        padding: 15px;
        margin: 0 0 1em;

        strong {
            font-weight: bold;
        }
    }
    .secure-stamp {
        position: absolute;
        top: 10px;
        right: 10px;
        font-size: $font-size-s;
        color: rgb(255, 255, 255);
        background-color: rgb(153, 204, 51);
        padding: 5px 10px;
        border-radius: 50px;

        &:before {
            @include icon($icon-lock);
            color: $primary-white;
            margin-right: 6px;
            margin-top: 2px;
            float: left;
            font-size: $font-size-body;
            margin-left: 2px;
        }
    }

    .input-wrapper{
        width: 100%;
    }

    .address-lookup {
        .toggle-buttons {
            width: 100%;
            margin-top: 15px;

            .toggle-button {
                width: 50%;
            }
        }
    }

    .subtle {
        font-size: $font-size-s;
        margin-top: 10px;
        display: inline-block;
    }
}

.tails-view-delivery-services {
    &[data-state=searching] {
        .loader-shroud {
            position: relative;
            display: block;
            max-height: none;
            opacity: 1;
        }
    }
}

.tails-view-address-lookup {
    position: relative;

    .address-input, .chosen-address, .postcode-validation-error, .address-search-postcode, .address-picker {
        display: none;
    }

    .form-group.address-third_line {
        display: none;
    }

    .chosen-address {
        .chosen-address-string {
            width: 100%;
            display: block;
            padding-right: 80px !important;
        }
    }

    .address-search-postcode {
        display: block;
    }

    .form-row.city-postcode {
        @include flexbox(row, center, space-between);

        .form-group {
            width: 100%;
            flex: 1;
        }

        @include until($screen-tablet) {
            flex-direction: column;
            align-items: stretch;
        }
    }


    &[data-state=searching] {
        .loader-shroud {
            display: block;
            max-height: none;
            opacity: 1;
        }
    }

    &[data-mode=payment] {
        .loader-animation {
            display: none;
        }
    }

    &[data-state=invalid-postcode] {
        #search-postcode {
            border-bottom: 1px solid $product-error;
        }
    }

    &[data-state=pick-address] {
        .address-picker {
            display: block;
        }
    }

    &[data-state=address-complete] {
        .address-search-postcode {
            display: none;
        }
        .chosen-address {
            display: block;
        }
    }

    &[data-state=long-form] {
        .address-search-postcode {
            display: none;
        }

        .address-input {
            display: block;
        }
    }

    &[data-country=gb] {
       @include uk-address-form();
    }

    &[data-country=fr] {
        @include french-address-form();
    }

    .help-block.errors p {
        color: $product-error;
    }
}

.payment-summary-container {
    font-size: $font-size-m;
    width: 100%;
    overflow: hidden;
    position: relative;

}

.preorder-summary {
    margin: 3rem 0 2rem;

    .sub-line {
        display: flex;
        justify-content: space-between;

        dl, div {
            margin: 1rem 0 2rem;
        }
    }
}

.payment-summary-container,
.one-click-wet-body {
    width: 100%;

    .table-wrapper {
        @include inalign(middle);
        width: 100%;
        @include transition(all 0.3s);
        white-space: normal;
        position: relative;

        .loader-shroud {
            max-width: 640px;
        }

        .pet-row {
            .order-days, .order-pet {
                font-weight: 400;
            }

            .order-weight {
                @include inalign(middle);
                font-size: .8em;
                font-weight: bold;
            }
        }
    }

    .buttons {
        .show-ongoing {
            @include user-select(none);
            float: left;
            font-weight: normal;
        }
    }

    table {
        $base-color: $navy-01;
        $offer-color: $secondary-01;
        margin: auto;
        text-align: left;
        @include grid-blocks(6);

        &.trial tbody tr{
            &:nth-child(odd) {
                background-color: rgba($offer-color, 0.1);
            }

            &.total {
                background-color: $offer-color;
            }
        }

        td {
            padding: 10px 15px;
        }

        thead {
            font-weight: bold;
        }

        thead, tfoot {
            tr.tailored-oneoff-charge-message {
                font-size: $font-size-s;
                text-align: center;
            }
            tr.promo-code {
                td {
                    background-color: $pastel-lighter-frost;
                    border-top: 20px solid $primary-white;
                    padding: 15px;

                    p {
                        font-size: $font-size-s;
                        margin-top: 0;
                        margin-bottom: 5px;
                        @include at-least($screen-desktop) {
                            text-align: center;
                        }
                    }

                    label.modal-toggle {
                        text-decoration: underline;
                        cursor: pointer;
                        color: $primary-text-link;
                        margin: 0;

                        span {
                            font-size: $font-size-s;
                        }

                        @include at-least($screen-desktop) {
                            display: block;
                            text-align: center;
                        }
                    }
                }
            }
        }

        tbody {
            font-size: $font-size-body;

            tr:nth-child(odd) {
                background-color: $pastel-lighter-frost;
            }

            td {
                .before-discount {
                    font-weight: normal;
                    text-decoration: line-through;
                }

                &:nth-child(2) {
                    font-weight: bold;
                    text-align: right;
                    white-space: nowrap;
                }

                &.actions {
                    padding: 0 5px;
                    text-align: center;

                    > a {
                        min-width: 44px;
                        min-height: 44px;
                    }
                }
            }

            tr.pet-row {
                .actions {
                    .re-add-pet {
                        display: none;
                        font-size: 2.4rem;

                        &:before {
                            @include icon($icon-plus);
                        }
                    }

                    .remove-pet {
                        font-size: 2.4rem;

                        &:before {
                            @include icon($icon-trash);
                        }
                    }
                }
                &.pending {
                    td {
                        opacity: 0.5;
                    }
                    .actions {
                        opacity: 1;
                        color: $navy-04;
                        text-align: right;

                        .remove-pet {
                            display: none;
                        }

                        .re-add-pet {
                            display: inline-block;
                        }
                    }
                    .col-total {
                        font-size: 0;
                    }
                }
            }
            tr.total {
                color: $primary-white;
                font-weight: bold;
                background-color: $base-color;
            }
        }
    }
}

.payment-summary-container-redesign {
    $kibble: url("/static/images/products/price-table/dry-food.jpg");
    $scoop: url("/static/images/products/price-table/scoop.jpg");
    $tray: url("/static/images/products/price-table/wet-food.jpg");
    $treat: url("/static/images/products/price-table/dental-dailies.jpg");
    $training_treat: url("/static/images/products/price-table/good-dog-treats.jpg");
    $posh_treat: url("/static/images/products/price-table/salmon-biscuits.jpg");
    $duck_treat: url("/static/images/products/price-table/duck-biscuits.jpg");
    $lamb_treat: url("/static/images/products/price-table/lamb-biscuits.jpg");
    $chicken_treat: url("/static/images/products/price-table/chicken-biscuits.jpg");
    $pork_treat: url("/static/images/products/price-table/pork-biscuits.jpg");
    $poo_bags: url("/static/images/products/price-table/poo-bags.jpg");
    $new_poo_bags: url("/static/images/products/price-table/new-poo-bags.png");
    $poo_bag_holder: url("/static/images/products/price-table/poo-bag-holder.jpg");
    $yaker-treat: url("/static/images/products/price-table/yakers.jpg");
    $fresh_chews: url("/static/images/products/price-table/fresh-chews.png");
    $whimzees_treat: url("/static/images/products/price-table/whimzees-alligator.jpg");
    $whimzees_bone_treat: url("/static/images/products/price-table/whimzees-ricebone.jpg");
    $super_duck_treat: url("/static/images/products/price-table/superfood-duck-bites.jpg");
    $super_lamb_treat: url("/static/images/products/price-table/superfood-lamb-bites.jpg");
    $super_salmon_treat: url("/static/images/products/price-table/superfood-salmon-bites.jpg");
    $coldpress_duck_treat: url("/static/images/products/price-table/cold-pressed-duck-rewards.jpg");
    $coldpress_lamb_treat: url("/static/images/products/price-table/cold-pressed-lamb-rewards.png");
    $coldpress_salmon_treat: url("/static/images/products/price-table/cold-pressed-salmon-rewards.png");
    $coldpress_turkey_treat: url("/static/images/products/price-table/cold-pressed-turkey-rewards.png");
    $seriously_meaty_burger: url("/static/images/products/price-table/seriously-meaty-burgers.png");
    $seriously_meaty_jerky: url("/static/images/products/price-table/seriously-meaty-jerky.png");
    $natural_chews_treat: url("/static/images/products/price-table/natural-chews-gb.png");
    $natural_chews_de: url("/static/images/products/price-table/natural-chews-de.png");
    $yak_snack: url("/static/images/products/price-table/yak-snack.png");

    .order-tables {
        text-align: left;
        overflow: hidden;

        .food-table-header {
            display: flex;
            flex-direction: column;
            width: 100%;
            margin: $spacing-m 0 $spacing-s;

            .box-edit-links {
                display: flex;
                flex-direction: column;

                > a {
                    margin-top: $spacing-xxs;
                    font-size: $font-size-s;
                }
            }

            @include at-least($screen-tablet) {
                flex-direction: row;
                justify-content: space-between;
                align-items: center;

                .project-box-edit {
                    margin: 0;
                }
            }
        }

        .price-table-separator {
            height: $spacing-s;
            width: 150%;
            margin-left: -50%;
            background-color: $pastel-lighter-silver;
            position: absolute;
        }

        .pricing-card {
            display: flex;
            flex-wrap: wrap;
            border: solid $pastel-darker-silver 1px;
            border-radius: 8px;
            background-color: $primary-white;
            max-width: 336px;
            margin: 0 auto;

            .pricing-card-section {
                display: flex;
                flex: 0 0 100%;
                justify-content: space-evenly;

                p {
                    margin: 0;
                }

                .pricing-card-img-container {
                    padding: $spacing-xs 1.4rem $spacing-xs $spacing-xs;
                }

                .pricing-card-img {
                    width: 42px;
                    height: 42px;
                    color: $pastel-rose;
                }

                .trial-pricing {
                    border-bottom: solid $pastel-darker-silver 1px;
                }

                .pricing-card-period {
                    display: flex;
                    flex: 1 1 auto;
                    flex-direction: column;
                    align-items: flex-start;
                    justify-content: space-around;
                    padding: $spacing-xs 0;

                    .trial-offer {
                        font-family: $handwritten-font-family;
                        text-transform: capitalize;
                    }

                    .ongoing-price {
                        margin: auto 0;
                    }
                }

                .pricing-card-price {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-end;
                    justify-content: space-between;
                    padding: $spacing-xs $spacing-xs $spacing-xs 1.4rem;

                    .price-card-trial-price {
                        margin: auto 0;
                        font-size: $font-size-l;

                        &.trial-price-red {
                            margin: 0;
                            color: $product-error;
                        }
                    }

                    .price-card-trial-offer {
                        font-size: $font-size-body;
                        text-decoration: line-through;
                    }

                    .price-card-ongoing-price {
                        margin: auto 0;
                        font-size: $font-size-m;
                    }
                }
            }
        }

        input[name="order-toggle"] {
            display: none;
        }

        .order-table .next, .order-table .calorie-density-link, .order-table .delivery-smallprint,
        .ongoing-table .nutribar-price-page {
            display: none;
        }

        .delivery-smallprint {
          white-space: normal;
          margin-top: 1.5em;
          font-size: $font-size-s;
          margin-right: 4px;
        }

        #show-next:checked ~ .track .order-table .next {
            display: table;
        }

        #show-next:checked ~ .track .order-table .calorie-density-link {
            display: block;
        }

        #show-next:checked ~ .track .order-table .delivery-smallprint.checkout-orders {
            display: block;
        }

        #show-next:checked ~ .track .order-table .next .price-table-separator {
            display: block;
        }

        #show-first:checked ~ .track .trial-table {
            display: inline-block;
        }

        #show-first:checked ~ .track .order-table .next {
            display: none;
        }

        #show-first:checked ~ .track .ongoing-table .nutribar-price-page {
            display: none;
        }

        #show-next:checked ~ .track .ongoing-table .nutribar-price-page {
            display: block;
        }

        #show-next:checked ~ .track .order-table .first {
            display: none;
        }

        #show-first:checked ~ .track .order-table .calorie-density-link {
            display: none;
        }

        #show-first:checked ~ .track .order-table .delivery-smallprint {
            display: none;
        }

        .order-table {
            @include inalign(top);
            width: 50%;
            outline: none;
            white-space: normal;

            .first {
                .delivery-note-star {
                    display: none;
                }
            }
            .ongoing-subscription-hidden {
                display: none;
            }
        }

        table.price-table {
            margin-bottom: 0;

            tr {
                border-bottom: 1px solid $pastel-darker-silver;

                &:last-of-type {
                    border-bottom: none;
                }
            }
        }

        table.total-table {
            margin-top: 20px;
            border-top: 1px solid $pastel-darker-silver;
        }

        table {
            margin-top: $spacing-s;

            &:first-of-type {
                margin-top: 0;
            }
        }

        tr {
            &.total-row, &.delivery-row, &.promotion-row {
                .col-total {
                    text-align: right;
                }

                &:first-of-type {
                    td {
                        padding-top: $spacing-s;
                    }
                }

                &:last-of-type {
                    font-weight: bold;

                    td {
                        padding-top: $spacing-xs;
                    }
                }

                &:nth-last-of-type(2) {
                    border-bottom: 1px solid $navy-00;

                    td {
                        padding-bottom: $spacing-xs;
                    }
                }
            }

            &.promotion-row {
                color: $product-error;
            }

            &.pet-heading-row {
                font-weight: normal;
                text-align: center;
                color: $navy-01;

                .pet-heading {
                    padding: $spacing-xs $spacing-xxs;

                    h5 {
                        font-family: $handwritten-font-family;
                        font-size: $font-size-m;
                    }

                    .modal-toggle {
                        span {
                            font-size: $font-size-xs;
                        }
                    }
                }
            }

            &.pet-row {
                color: $primary-colour;
                background-color: transparent;
                border-top: 1px solid $pastel-lighter-frost;

                td {
                    padding: $spacing-s 0;

                    &.col-product-details {
                        min-height: 190px;
                        margin: 0 $spacing-xxs;

                        @include at-least($screen-desktop) {
                            margin-left: $spacing-m;
                        }

                        @include flexbox(column, center, flex-start);
                        .details-item-row {
                            text-align: left;
                            width: 100%;
                            margin-bottom: $spacing-xs;

                            .why-not-free {
                                display: block;
                                font-size: $font-size-xs;
                                font-weight: normal;
                            }

                            p {
                                margin: 0;

                                &.item-description {
                                    color: $navy-01;
                                }
                            }
                        }

                        .details-total-row {
                            font-weight: bold;
                            width: 100%;
                            color: $navy-00;

                            .before-discount {
                                text-decoration: line-through;
                                font-size: $font-size-s;
                                padding-right: 5px;
                                font-weight: normal;
                                color: $navy-01;
                            }
                        }

                        .rm-treats-desktop.redesign,
                        .rm-wetfood-desktop.redesign {
                            align-self: flex-start;
                        }

                        .details-remove-row {
                            width: 100%;
                            margin-top: $spacing-xxs;

                            .rm-wetfood,
                            .rm-treats {
                                display: block;
                                font-size: $font-size-xs;
                                font-weight: normal;
                            }

                            @include at-least($screen-tablet) {
                                margin-top: $spacing-xs;
                            }
                        }

                        .item-description {
                            color: $navy-01;
                            font-size: $font-size-s;
                        }
                    }
                }

                .col-product-image {
                    margin: 0;
                    padding: 0;
                    height: 130px;
                    width: 50%;

                    .product-image {
                        background-position: center;
                        height: 160px;
                        width: 160px;
                        background-size: contain;
                        margin: 0 auto;
                        background-repeat: no-repeat;

                        &.kibble {background-image: $kibble;}
                        &.scoop {background-image: $scoop;}
                        &.tray {background-image: $tray;}
                        &.treat {background-image: $treat;}
                        &.training_treat {background-image: $training_treat;}
                        &.posh_treat {background-image: $posh_treat;}
                        &.duck_treat {background-image: $duck_treat;}
                        &.lamb_treat {background-image: $lamb_treat;}
                        &.chicken_treat {background-image: $chicken_treat;}
                        &.pork_treat {background-image: $pork_treat;}
                        &.poo_bags {background-image: $poo_bags;}
                        &.new_poo_bags {background-image: $new_poo_bags;}
                        &.poo_bag_holder {background-image: $poo_bag_holder;}
                        &.yaker_treat {background-image: $yaker_treat;}
                        &.fresh_chews_small {background-image: $fresh_chews;}
                        &.fresh_chews_medium {background-image: $fresh_chews;}
                        &.fresh_chews_large {background-image: $fresh_chews;}
                        &.whimzees_treat {background-image: $whimzees_treat;}
                        &.whimzees_bone_treat {background-image: $whimzees_bone_treat;}
                        &.super_duck_treat {background-image: $super_duck_treat;}
                        &.super_lamb_treat {background-image: $super_lamb_treat;}
                        &.super_salmon_treat {background-image: $super_salmon_treat;}
                        &.coldpress_duck_treat {background-image: $coldpress_duck_treat;}
                        &.cold_pressed_lamb_rewards {background-image: $coldpress_lamb_treat;}
                        &.cold_pressed_salmon_rewards {background-image: $coldpress_salmon_treat;}
                        &.cold_pressed_turkey_rewards {background-image: $coldpress_turkey_treat;}
                        &.seriously_meaty_snacks_burger {background-image: $seriously_meaty_burger;}
                        &.seriously_meaty_snacks_jerky {background-image: $seriously_meaty_jerky;}
                        &.natural_chews_treat {background-image: $natural_chews_treat;}
                        &.natural_chews_de {background-image: $natural_chews_de;}
                        &.yak_snack_treat {background-image: $yak_snack;}
                    }
                }
            }
        }

        tr.promo-code {
            td {
                background-color: $pastel-lighter-frost;
                border-top: 20px solid $primary-white;
                padding: $spacing-xs;

                p {
                    font-size: $font-size-s;
                    margin-top: 0;
                    margin-bottom: 5px;
                }

                label.modal-toggle {
                    text-decoration: underline;
                    cursor: pointer;
                    color: $primary-text-link;
                    margin: 0;

                    span {
                        font-size: $font-size-s;
                    }

                    @include at-least($screen-desktop) {
                        display: block;
                    }
                }
            }
        }
    }


    .table-wrapper {
        position: relative;
    }
}

// PRICE TABLE DEFAULT
.payment-summary-experiment {
    $feeding_plan: url("/static/images/products/price-table/feeding-plan.png");

    .review-box {
        text-align: left;
        padding: 25px 0 25px 0;

    }

    .order-tables{
        .price-table-separator {
            height: 10px;
            width: 150%;
            margin-left: -50%;
            background-color: #E4EBF3;
            position: absolute;
        }
    }

    #show-first:checked {
        ~ .tabs-comparison .tab {
            &.first {
                background-color: $primary-white;
                color: $primary-colour;
            }
        }
    }

    #show-next:checked {
        ~ .tabs-comparison .tab {
            &.next {
                background-color: $primary-white;
                color: $primary-colour;
            }
        }
    }

    .tabs-comparison {
        @include flexbox(row, stretch, center);
        margin-top: 10px;

        .tab {
            flex: 1;
            cursor: pointer;
            @include flexbox(row, center, center);
            font-weight: 700;
            background: #F3F7FB;
            color: $navy-02;
            padding: $spacing-xxs;
            padding-top: 0;
            line-height: 1.5;
            position: relative;
            z-index: 0;
            height: 85px;
            font-size: 16px;
            border-top-left-radius: 12px;
            border-top-right-radius: 12px;
        }
    }

    .pricing-total-section-dark-wrapper {
        width: 100vw;
        position: relative;
        left: calc(-50vw + 50%);
        background-color: $primary-blueberry;
        overflow-x: visible;
    }

    .pricing-total-section-dark {
        display: none;

        .promo-code-wrapper {
            padding: 0 2.6rem;
            text-align: left;

            .promo-code, p {
                margin: 0;
                color: $primary-white;
                font-size: $font-size-xs;
            }

            p:nth-of-type(2) {
                padding-bottom: 8px;
            }

            label.modal-toggle {
                color: $primary-white;
                margin-top: $spacing-s;
                font-size: $font-size-s;
            }

            .notification-content p {
                color: var(--primary-black);
            }
        }

        .delivery-smallprint {
            margin: 0;
            padding: 2.6rem;
            color: $primary-white;
            font-size: $font-size-xs;
        }
    }

    .pet-heading {
        font-weight: normal;
        background-color: $primary-white;
        padding: 18px 11px 5px 11px;
        margin-top: -10px;
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
        z-index: 1;

        h4 {
            font-weight: 600;
        }

        .modal-toggle {
            span {
                font-size: $font-size-xs;
            }
        }

        .days-of-food {
            h4 {
                font-size: 16px;
                font-weight: 600;
            }

            h5 {
                font-size: 13px;
                color: var(--primary-black);
                margin-top: 7px;
                margin-bottom: 7px;
            }

            .edit-price {
                display: flex;
                justify-content: space-between;
                align-items: center;

                > h5 {
                    font-size: 13px;
                    color: var(--primary-black);

                    .ongoing-price.trial {
                        text-decoration: line-through;
                        font-size: var(--font-size-body);
                        color: var(--primary-blueberry-30);
                        padding-right: 0;
                    }

                    .ongoing-price {
                      font-weight: var(--font-weight-bold);
                      font-size: var(--font-size-body);
                      padding-right: 0;
                    }

                    .trial-price {
                        font-weight: var(--font-weight-bold);
                        font-size: var(--font-size-body);
                    }
                }

                > a {
                    font-size: 13px !important;
                    font-weight: 600;
                    color: $primary-text-link;
                    padding-bottom: 5px;
                }
            }
        }

        @include at-least($screen-tablet) {
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            .project-box-edit {
                margin: 0;
            }
        }
    }

    .price-table-wrapper {
        width: 100%;
        background-color: $primary-white;
        padding: 0 11px 0 11px;
        box-shadow: 0px 14px 30px 0px rgba(0, 0, 0, 0.06);
    }

    .price-table-wrapper:last-of-type {
        border-bottom-left-radius: 12px;
        border-bottom-right-radius: 12px;
    }

    table.price-table {
        margin: 0;
        background-color: $primary-white;

        tr {
            &.pet-row {
                color: $primary-colour;
                background-color: transparent;
                border-top: none;

                .col-product-image {
                    width: 25%;

                    .quantity-pill {
                        display: inline-block;
                        margin: 6px 0 0 6px;
                        padding: 5px 7px;
                        font-family: $default-font-family;
                        font-size: $base-font-size;
                        font-weight: $font-weight-regular;
                        border-radius: 4px;
                        background-color: var(--primary-blueberry);
                        color: $primary-white;
                    }

                    .product-image {
                        background-position: center;
                        height: 136px;
                        width: 136px;
                        border-radius: 12px;
                        background-size: contain;
                        margin-right: 18px;
                        background-repeat: no-repeat;
                        @media (max-width: 425px) {
                            height: 110px;
                            width: 110px;
                          }

                        &.feeding-plan {
                            background-image: $feeding_plan;
                        }
                    }
                }

                .col-product-details {
                    padding-top: 20px;
                    padding-bottom: 0;
                    min-height: 170px;

                    .details-item-row {
                        .item-title {
                            font-size: var(--font-size-s);
                            color: var(--primary-black);
                            font-weight: var(--font-weight-bold);
                        }

                        .item-description{
                            color: var(--primary-black);
                            font-weight: var(--font-weight-medium);
                            font-size: var(--font-size-xs);
                            padding-top: 10px;
                        }
                    }
                    .details-total-row {
                        color: var(--primary-black);

                        .details-total-row-trial{
                            color: var(--discount);
                            font-size: var(--font-size-m);
                        }

                        .before-discount{
                            display: block;
                            font-weight: var(--font-weight-medium);
                            font-size: var(--font-size-xs);
                        }
                    }
                }
            }
        }
    }

    .total-table-wrapper-experiment {
        padding: 1.8rem 2.6rem 0;
    }

    table.total-table-price-experiment {
        border-top: 0;
        color: $primary-white;
        text-align: left;
        margin-bottom: 0;

        td {
            padding-left: 0;
            padding-right: 0;
        }

        .col-total {
            text-align: right;
            font-weight: bold;

            .ongoing-price {
                text-decoration: line-through;
                font-weight: var(--font-weight-regular);
                color: var(--primary-blueberry-30);
                padding: 0;
                margin: 0;
            }
        }

        tr.delivery-row:nth-last-of-type(1) {
            border-top: 1px solid $primary-white;
        }

        tr.delivery-row:nth-last-of-type(2) {
            border-bottom: 1px solid $primary-white;
        }

        tr.delivery-row, tr.total-row {
            td.col-description {
                font-weight: var(--font-weight-bold);
            }
        }

      tr.pet {
          td.col-description {
              font-weight: var(--font-weight-bold);
          }
      }
    }
}

body.signup-price-summary.price-per-pet {
    $kibble: url("/static/images/products/price-table/dry-food.jpg");
    $scoop: url("/static/images/products/price-table/scoop.jpg");
    $tray: url("/static/images/products/price-table/wet-food.jpg");
    $premium_tray: url("/static/images/products/price-table/premium-wet-food.jpg");
    $treat: url("/static/images/products/price-table/dental-dailies.jpg");
    $training_treat: url("/static/images/products/price-table/good-dog-treats.jpg");
    $posh_treat: url("/static/images/products/price-table/salmon-biscuits.jpg");
    $duck_treat: url("/static/images/products/price-table/duck-biscuits.jpg");
    $lamb_treat: url("/static/images/products/price-table/lamb-biscuits.jpg");
    $chicken_treat: url("/static/images/products/price-table/chicken-biscuits.jpg");
    $pork_treat: url("/static/images/products/price-table/pork-biscuits.jpg");
    $poo_bags: url("/static/images/products/price-table/poo-bags.jpg");
    $new_poo_bags: url("/static/images/products/price-table/new-poo-bags.png");
    $poo_bag_holder: url("/static/images/products/price-table/poo-bag-holder.jpg");
    $yaker-treat: url("/static/images/products/price-table/yakers.jpg");
    $fresh_chews: url("/static/images/products/price-table/fresh-chews.png");
    $whimzees_treat: url("/static/images/products/price-table/whimzees-alligator.jpg");
    $whimzees_bone_treat: url("/static/images/products/price-table/whimzees-ricebone.jpg");
    $super_duck_treat: url("/static/images/products/price-table/superfood-duck-bites.jpg");
    $super_lamb_treat: url("/static/images/products/price-table/superfood-lamb-bites.jpg");
    $super_salmon_treat: url("/static/images/products/price-table/superfood-salmon-bites.jpg");
    $coldpress_duck_treat: url("/static/images/products/price-table/cold-pressed-duck-rewards.jpg");
    $coldpress_lamb_treat: url("/static/images/products/price-table/cold-pressed-lamb-rewards.png");
    $coldpress_salmon_treat: url("/static/images/products/price-table/cold-pressed-salmon-rewards.png");
    $coldpress_turkey_treat: url("/static/images/products/price-table/cold-pressed-turkey-rewards.png");
    $seriously_meaty_burger: url("/static/images/products/price-table/seriously-meaty-burgers.png");
    $seriously_meaty_jerky: url("/static/images/products/price-table/seriously-meaty-jerky.png");
    $natural_chews_treat: url("/static/images/products/price-table/natural-chews-gb.png");
    $natural_chews_de: url("/static/images/products/price-table/natural-chews-de.png");
    $yak_snack: url("/static/images/products/price-table/yak-snack.png");

    .table-wrapper.order-table {
        table {
            thead {
                margin-bottom: 40px;

                tr {
                    font-size: $font-size-m
                }

                .days-of-food{
                    padding-top: 0px;
                    padding-bottom: 0px;
                    font-weight: normal;
                    font-size: $font-size-body;
                }
            }

            tbody {
                font-size: 1.6rem;

                @include at-least($screen-desktop) {
                    font-size: 1.8rem;
                }
            }

            tr {
                td {
                    white-space: normal;

                    &:nth-child(2) {
                        font-weight: normal;
                        text-transform: none;
                    }
                }

                &.eighteen-days-modal {
                    background-color: transparent;

                    th {
                        font-weight: normal;
                        padding-left: 15px;
                    }
                }

                &.pet-heading-row {
                    background-color: transparent;

                    .pet-heading {
                        padding: 40px 0px 10px 15px;
                        font-weight: bold;
                        font-size: $font-size-m;
                        width: inherit;

                        &:only-child {
                            column-span: 2;
                        }

                        .pet-box-link-mobile {
                            display: block;

                            @include at-least($screen-tablet) {
                                display: none;
                            }
                        }
                    }

                    .pet-box-link-desktop {
                        display: none;
                        padding: 40px 0px 10px;
                        text-align: right;

                        > a {
                            font-size: $font-size-s;
                            font-weight: normal;
                        }

                        @include at-least($screen-tablet) {
                            display: table-cell;
                        }
                    }
                }

                &.delivery-row {
                    color: $primary-colour;
                    background-color: transparent;
                    border-top: 1px solid $pastel-lighter-frost;
                    height: 100px;
                    @include at-least($screen-desktop) {
                        padding: 15px 5px;
                    }
                    padding: 30px 5px;

                    .col-total {
                        font-weight: bold;
                    }
                }

                &.pet-row {
                    color: $primary-colour;
                    background-color: transparent;
                    border-top: 1px solid $pastel-lighter-frost;

                    td {
                        @include at-least($screen-desktop) {
                            padding: 15px 5px;
                        }
                        padding: 30px 5px;

                        &.col-product-details {
                            min-height: 190px;
                            margin-right: 10px;
                            margin-left: 10px;

                            @include at-least($screen-desktop) {
                                margin-left: 30px;
                            }
                            @include flexbox(column, center, space-between);

                            .details-item-row {
                                text-align: left;
                                width: 100%;

                                .why-not-free-mobile {
                                    display: block;
                                    font-size: $font-size-s;
                                    font-weight: normal;
                                }

                                .rm-wetfood-mobile,
                                .rm-treats-mobile {
                                    display: block;
                                    font-size: $font-size-s;
                                    font-weight: normal;
                                }

                                p {
                                    margin: 0;

                                    &.item-description {
                                        color: $navy-01;
                                    }
                                }

                                @include at-least($screen-tablet) {
                                    .rm-wetfood-mobile,
                                    .why-not-free-mobile,
                                    .rm-treats-mobile {
                                        display: none;
                                    }
                                }
                            }

                            .details-total-row {
                                font-weight: bold;
                                width: 100%;

                                .why-not-free-desktop,
                                .rm-wetfood-desktop,
                                .rm-treats-desktop {
                                    display: none;
                                }

                                .why-not-free,
                                .rm-wetfood,
                                .rm-treats {
                                    font-weight: normal;
                                    float: left;
                                }

                                .before-discount {
                                    text-decoration: line-through;
                                    font-size: $font-size-s;
                                    color: $navy-01;
                                    padding-right: 5px;
                                }

                                @include at-least($screen-tablet) {
                                    .why-not-free-desktop,
                                    .rm-wetfood-desktop,
                                    .rm-treats-desktop {
                                        display: block;
                                        font-size: $font-size-s;
                                    }
                                }
                            }

                            .item-description {
                                color: $navy-01;
                                font-size: $font-size-s;
                            }
                        }
                    }
                    .col-product-image {
                        margin: 0;
                        padding: 0;
                        height: 130px;
                        width: 130px;

                        .product-image {
                            background-position: center;
                            height: 160px;
                            width: 160px;
                            background-size: contain;
                            margin: 0 auto;
                            background-repeat: no-repeat;

                            &.kibble {background-image: $kibble;}
                            &.scoop {background-image: $scoop;}
                            &.tray {background-image: $tray;}
                            &.premium_tray {background-image: $premium_tray;}
                            &.treat {background-image: $treat;}
                            &.training_treat {background-image: $training_treat;}
                            &.posh_treat {background-image: $posh_treat;}
                            &.duck_treat {background-image: $duck_treat;}
                            &.lamb_treat {background-image: $lamb_treat;}
                            &.chicken_treat {background-image: $chicken_treat;}
                            &.pork_treat {background-image: $pork_treat;}
                            &.poo_bags {background-image: $poo_bags;}
                            &.new_poo_bags {background-image: $new_poo_bags;}
                            &.poo_bag_holder {background-image: $poo_bag_holder;}
                            &.yaker_treat {background-image: $yaker_treat;}
                            &.fresh_chews_small {background-image: $fresh_chews;}
                            &.fresh_chews_medium {background-image: $fresh_chews;}
                            &.fresh_chews_large {background-image: $fresh_chews;}
                            &.whimzees_treat {background-image: $whimzees_treat;}
                            &.whimzees_bone_treat {background-image: $whimzees_bone_treat;}
                            &.super_duck_treat {background-image: $super_duck_treat;}
                            &.super_lamb_treat {background-image: $super_lamb_treat;}
                            &.super_salmon_treat {background-image: $super_salmon_treat;}
                            &.coldpress_duck_treat {background-image: $coldpress_duck_treat;}
                            &.cold_pressed_lamb_rewards {background-image: $coldpress_lamb_treat;}
                            &.cold_pressed_salmon_rewards {background-image: $coldpress_salmon_treat;}
                            &.cold_pressed_turkey_rewards {background-image: $coldpress_turkey_treat;}
                            &.seriously_meaty_snacks_burger {background-image: $seriously_meaty_burger;}
                            &.seriously_meaty_snacks_jerky {background-image: $seriously_meaty_jerky;}
                            &.natural_chews_treat {background-image: $natural_chews_treat;}
                            &.natural_chews_de {background-image: $natural_chews_de;}
                            &.yak_snack_treat {background-image: $yak_snack;}
                        }
                    }
                }

                &.total-row {
                    color: $primary-white;
                    background-color: $primary-colour;
                    font-weight: bold;
                    height: 62px;
                    @include at-least($screen-desktop) {
                        padding: 15px 5px;
                    }
                    padding: 30px 5px;

                    .col-total {
                        text-align: right;
                        font-weight: bold;
                    }
                }

                &.total-row.pet {
                    background-color: $primary-white;
                    color: $primary-colour;
                    height: 70px;
                    border-top: 1px solid $primary-colour;
                    border-bottom: 1px solid $primary-colour;
                }

                &.foot-note {
                    background-color: transparent;

                    td {
                        padding: 0;
                        color: $navy-04;
                        font-size: $font-size-s;
                    }
                }
            }
        }

        .modal-head {
            height: 200px;
            background-repeat: no-repeat;
            background-position: center;
            background-size: auto 180px;

            &.kibble {background-image: $kibble;}
            &.scoop {background-image: $scoop;}
            &.tray {background-image: $tray;}
            &.premium_tray {background-image: $premium_tray;}
            &.treat {background-image: $treat;}
            &.training_treat {background-image: $training_treat;}
            &.posh_treat {background-image: $posh_treat;}
            &.duck_treat {background-image: $duck_treat;}
            &.lamb_treat {background-image: $lamb_treat;}
            &.chicken_treat {background-image: $chicken_treat;}
            &.pork_treat {background-image: $pork_treat;}
            &.poo_bags {background-image: $poo_bags;}
            &.new_poo_bags {background-image: $new_poo_bags;}
            &.poo_bag_holder {background-image: $poo_bag_holder;}
            &.yaker_treat {background-image: $yaker_treat;}
            &.fresh_chews_small {background-image: $fresh_chews;}
            &.fresh_chews_medium {background-image: $fresh_chews;}
            &.fresh_chews_large {background-image: $fresh_chews;}
            &.whimzees_treat {background-image: $whimzees_treat;}
            &.whimzees_bone_treat {background-image: $whimzees_bone_treat;}
            &.super_duck_treat {background-image: $super_duck_treat;}
            &.super_lamb_treat {background-image: $super_lamb_treat;}
            &.super_salmon_treat {background-image: $super_salmon_treat;}
            &.coldpress_duck_treat {background-image: $coldpress_duck_treat;}
            &.cold_pressed_lamb_rewards {background-image: $coldpress_lamb_treat;}
            &.cold_pressed_salmon_rewards {background-image: $coldpress_salmon_treat;}
            &.cold_pressed_turkey_rewards {background-image: $coldpress_turkey_treat;}
            &.seriously_meaty_snacks_burger {background-image: $seriously_meaty_burger;}
            &.seriously_meaty_snacks_jerky {background-image: $seriously_meaty_jerky;}
            &.natural_chews_treat {background-image: $natural_chews_treat;}
            &.natural_chews_de {background-image: $natural_chews_de;}
            &.yak_snack_treat {background-image: $yak_snack;}
        }

        .total-discount-test-variant {
            color: $product-error;
            margin: 0;
        }
    }
    .calorie-density-link {
        padding: 10px 0;
    }
}

.price-per-pet-payment-summary {
    .order-tables {
        .track {
            transition: none;
        }

        #show-next:checked ~ .track .order-table .first {
            display: none;
        }
    }
}

.checkout-orders {
    $kibble: url("/static/images/products/price-table/dry-food.jpg");
    $scoop: url("/static/images/products/price-table/scoop.jpg");
    $tray: url("/static/images/products/price-table/wet-food.jpg");
    $premium_tray: url("/static/images/products/price-table/premium-wet-food.jpg");
    $treat: url("/static/images/products/price-table/dental-dailies.jpg");
    $training_treat: url("/static/images/products/price-table/good-dog-treats.jpg");
    $posh_treat: url("/static/images/products/price-table/salmon-biscuits.jpg");
    $duck_treat: url("/static/images/products/price-table/duck-biscuits.jpg");
    $lamb_treat: url("/static/images/products/price-table/lamb-biscuits.jpg");
    $chicken_treat: url("/static/images/products/price-table/chicken-biscuits.jpg");
    $pork_treat: url("/static/images/products/price-table/pork-biscuits.jpg");
    $poo_bags: url("/static/images/products/price-table/poo-bags.jpg");
    $new_poo_bags: url("/static/images/products/price-table/new-poo-bags.png");
    $poo_bag_holder: url("/static/images/products/price-table/poo-bag-holder.jpg");
    $yaker-treat: url("/static/images/products/price-table/yakers.jpg");
    $fresh_chews: url("/static/images/products/price-table/fresh-chews.png");
    $whimzees_treat: url("/static/images/products/price-table/whimzees-alligator.jpg");
    $whimzees_bone_treat: url("/static/images/products/price-table/whimzees-ricebone.jpg");
    $super_duck_treat: url("/static/images/products/price-table/superfood-duck-bites.jpg");
    $super_lamb_treat: url("/static/images/products/price-table/superfood-lamb-bites.jpg");
    $super_salmon_treat: url("/static/images/products/price-table/superfood-salmon-bites.jpg");
    $coldpress_duck_treat: url("/static/images/products/price-table/cold-pressed-duck-rewards.jpg");
    $coldpress_lamb_treat: url("/static/images/products/price-table/cold-pressed-lamb-rewards.png");
    $coldpress_salmon_treat: url("/static/images/products/price-table/cold-pressed-salmon-rewards.png");
    $coldpress_turkey_treat: url("/static/images/products/price-table/cold-pressed-turkey-rewards.png");
    $seriously_meaty_burger: url("/static/images/products/price-table/seriously-meaty-burgers.png");
    $seriously_meaty_jerky: url("/static/images/products/price-table/seriously-meaty-jerky.png");
    $natural_chews_treat: url("/static/images/products/price-table/natural-chews-gb.png");
    $natural_chews_de: url("/static/images/products/price-table/natural-chews-de.png");
    $yak_snack: url("/static/images/products/price-table/yak-snack.png");

    width: 100%;
    overflow: hidden;

    .days-of-food-text {
        margin-left: 15px;
    }

    table {
        tbody {
            font-size: 1.6rem;
            @include at-least($screen-desktop) {
                font-size: 1.8rem;
            }
        }

        tr {
            td {
                white-space: normal;

                &:nth-child(2) {
                    font-weight: normal;
                    text-transform: none;
                }
            }
            &.pet-heading-row {
                background-color: transparent;

                .pet-heading {
                    padding: 10px 0px 10px 15px;
                    font-size: $font-size-m;
                    width: inherit;

                    h5 {
                        text-align: left;
                    }
                }
            }
            &.delivery-row {
                color: $primary-colour;
                background-color: transparent;
                border-top: 1px solid $pastel-lighter-frost;
                height: 70px;

                .col-total {
                    font-weight: bold;
                    text-align: right;
                }
            }
            &.pet-row {
                color: $primary-colour;
                background-color: transparent;
                border-top: 1px solid $pastel-lighter-frost;

                td {
                    @include at-least($screen-desktop) {
                        padding: 15px 5px;
                    }
                    padding: 24px 5px;

                    &.col-product-details {
                        min-height: 130px;
                        margin-right: 10px;
                        margin-left: 10px;
                        @include flexbox(column, center, space-between);

                        .details-item-row {
                            text-align: left;
                            width: 100%;

                            p {
                                margin: 0;

                                &.item-description {
                                    color: $navy-01;
                                }
                            }
                        }

                        .details-total-row {
                            font-weight: bold;
                            width: 100%;
                            text-align: right;
                            padding-top: 16px;

                            .before-discount {
                                text-decoration: line-through;
                                font-size: $font-size-s;
                                color: $navy-01;
                                padding-right: 5px;
                                font-weight: normal;
                            }
                        }

                        .item-description {
                            color: $navy-01;
                            font-size: $font-size-s;
                        }
                    }
                }
                .col-product-image {
                    margin: 0;
                    padding: 0;
                    height: 86px;
                    width: 86px;

                    label {
                        cursor: default;
                    }
                    .product-image {
                        background-position: center;
                        height: 86px;
                        width: 86px;
                        background-size: contain;
                        margin: 0 auto;
                        background-repeat: no-repeat;
                        &.kibble {background-image: $kibble;}
                        &.scoop {background-image: $scoop;}
                        &.tray {background-image: $tray;}
                        &.premium_tray {background-image: $premium_tray;}
                        &.treat {background-image: $treat;}
                        &.training_treat {background-image: $training_treat;}
                        &.posh_treat {background-image: $posh_treat;}
                        &.duck_treat {background-image: $duck_treat;}
                        &.lamb_treat {background-image: $lamb_treat;}
                        &.chicken_treat {background-image: $chicken_treat;}
                        &.pork_treat {background-image: $pork_treat;}
                        &.poo_bags {background-image: $poo_bags;}
                        &.new_poo_bags {background-image: $new_poo_bags;}
                        &.poo_bag_holder {background-image: $poo_bag_holder;}
                        &.yaker_treat {background-image: $yaker_treat;}
                        &.fresh_chews_small {background-image: $fresh_chews;}
                        &.fresh_chews_medium {background-image: $fresh_chews;}
                        &.fresh_chews_large {background-image: $fresh_chews;}
                        &.whimzees_treat {background-image: $whimzees_treat;}
                        &.whimzees_bone_treat {background-image: $whimzees_bone_treat;}
                        &.super_duck_treat {background-image: $super_duck_treat;}
                        &.super_lamb_treat {background-image: $super_lamb_treat;}
                        &.super_salmon_treat {background-image: $super_salmon_treat;}
                        &.coldpress_duck_treat {background-image: $coldpress_duck_treat;}
                        &.cold_pressed_lamb_rewards {background-image: $coldpress_lamb_treat;}
                        &.cold_pressed_salmon_rewards {background-image: $coldpress_salmon_treat;}
                        &.cold_pressed_turkey_rewards {background-image: $coldpress_turkey_treat;}
                        &.seriously_meaty_snacks_burger {background-image: $seriously_meaty_burger;}
                        &.seriously_meaty_snacks_jerky {background-image: $seriously_meaty_jerky;}
                        &.natural_chews_treat {background-image: $natural_chews_treat;}
                        &.natural_chews_de {background-image: $natural_chews_de;}
                        &.yak_snack_treat {background-image: $yak_snack;}
                    }
                }
            }

            &.total-row {
                color: $primary-white;
                background-color: $primary-colour;
                font-weight: bold;
                height: 62px;
                @include at-least($screen-desktop) {
                    padding: 15px 5px;
                }
                padding: 30px 5px;

                .col-total {
                    text-align: right;
                    font-weight: bold;
                }
            }

            &.total-row.pet {
                background-color: $primary-white;
                color: $primary-colour;
                height: 70px;
                border-top: 1px solid $primary-colour;
                border-bottom: 1px solid $primary-colour;
            }
        }
    }
    .commitment-message {
        width: 100%;
        p {
            width: inherit;
            text-align: center;
        }
    }
    .order-tables {
        position: relative;
        overflow: hidden;

        .track {
            width: 200%;
            white-space: nowrap;
            @include transition(all 0.3s);
        }
    }

    .commitment-message {
        width: 100%;

        p {
            width: inherit;
            text-align: center;
        }
    }
}

.tails-view-filter-list {
    .value {
        display: none;
    }

    &.nojs {
        .value {
            display: block;
        }

        .searcher, .list {
            display: none;
        }
    }

    .search {
        position: relative;
        z-index: 2;
        width: 100%;
        box-shadow: none;
        @include placeholder {
            @media screen and (max-width: $screen-phone) {
                font-size: $font-size-s;
                line-height: 26px;
                font-weight: normal;
            }
        }
    }

    .list {
        text-align: left;
        position: relative;
        z-index: 1;
        background-color: $primary-white;
        border-radius: 4px;
        overflow: auto;
        @include transition(opacity 0.3s, max-height 0.3s);
        @extend %invisible-scrollbar;

        .item {
            padding: 8px 12px;
            line-height: 2;
            cursor: pointer;

            &.selected {
                font-weight: normal;
                color: $navy-01;
            }

            &.sticky {
                position: sticky;
                bottom: 0;
                left: 0;
            }
        }
    }

    &[data-state=init], &[data-state=complete], &[data-state=error] {
        .list {
            opacity: 0;
            max-height: 0;
            pointer-events: none;
        }
    }

    &[data-state=searching] {
        .list {
            opacity: 1;
            pointer-events: all;

            @include at-least($screen-desktop) {
                max-height: 400px;
            }
        }
    }

    &[data-state=complete] {
        .status {
            &:before {
                @include icon($icon-check);
                color: $product-success;
                font-size: $font-size-m;
            }
        }
    }
}

.food-type-wrapper {
    .header {
        background-color: $pastel-lighter-frost;
        border-radius: 8px;
        margin-top: 1rem;

        > label {
            @include flexbox(row, center, space-between);
            padding: 10px;
            @include user-select(none);
            margin-bottom: 0;

            .title-and-types {
                flex-grow: 1;
                margin-left: 20px;
                text-align: left;
                @include flexbox(column, start, space-between);
                @include flex-wrap(wrap);

                span {
                    line-height: $font-size-body;
                }

                .title {
                    width: 100%;
                    font-size: $font-size-body;
                    margin-bottom: 5px;
                }

                .types {
                    font-size: $font-size-xs;
                }

                @include at-least($screen-phone) {
                    @include flex-wrap(no-wrap);

                    .title, .types {
                        width: auto;
                    }
                }
            }
        }
    }

    > input[type=checkbox] {
        display: none;
    }

    > input[type=checkbox]:checked ~ .form-container {
        max-height: none;

        .slider {
            @include translated(0, 0, 0);
        }
    }

    .searcher {
        width: 100%;

        input::placeholder {
            font-size: $font-size-s;
            font-weight: normal;
        }
    }

    .form-container {
        overflow: hidden;
        max-height: 0;

        .slider {
            @include translated(0, -100%, 0);
            @include transition(all 0.3s);
            background-color: $primary-white;
            border-top: none;
            padding: 1.4rem 0 1.4rem 75px;
            text-align: left;
        }

        .row {
            @include flexbox(column, start, space-between);
            margin-bottom: 40px;
            text-align: left;

            .col {
                &:first-child {
                    margin-bottom: 20px;
                }
            }

            @include at-least($screen-tablet) {
                @include flex-direction(row);

                .col {
                    flex-grow: 1;
                    width: calc(50% - 20px);
                    &:first-child {
                        margin-right: 40px;
                        margin-bottom: 0;
                    }
                }
            }

            &:last-of-type {
                margin-bottom: 0;
            }
        }

        .list {
            padding: 0;
        }

        .current-wet-food-amount {
            @include inalign(middle);
        }
    }

    .title {
      font-size: $font-size-m;
    }

    .image {
        @include size(45px);
        min-width: 45px;
        background-size: cover;
        background-position: center;
        margin-left: 10px;
    }

    &[data-type=dry-food] {
        .image {
            background-image: url("/static/images/consultation/diet/dryfood.png");
        }
    }

    &[data-type=wet-food] {
        .image {
            background-image: url("/static/images/consultation/diet/wetfood.png");
        }
    }

    &[data-type=other-food] {
        .image {
            background-image: url("/static/images/consultation/diet/treats.png");
        }
    }
}

.slider-wrapper {
    .options {
        @include flexbox(row, flex-end, flex-end);
        margin-bottom: 0;
        overflow: hidden;

        .option {
            @include inalign(bottom);
            @include flex-grow(1);
            padding: 0 5px;
        }

        p {
            margin-bottom: 20px;
        }

        label {
            width: 100%;
            cursor: pointer;
            padding: 10px 5px 0;
            margin: 0 0 -10px 0;
            display: inline-block;

            img {
                opacity: 0;
                @include transform(scale(1));
                @include transition(all 0.3s);
                width: 100%;
                margin-bottom: 20px;
                pointer-events: none;
                position: relative;
                left: -100%;
            }

            img.greyscale {
                opacity: 1;
                position: relative;
                left: 0px;
            }
        }

        input[type=radio]:checked ~ label {
            img {
                opacity: 1;
                @include transform(scale(1.3));
            }

            img.greyscale {
                opacity: 0;
            }
        }

        @include at-least($screen-tablet) {
            .option {
                padding: 0 10px;
            }

            label {
                padding: 10px 10px 0;
            }
        }
    }

    .pointer-track {
        display: block;
        width: 100%;
        height: 20px;
        overflow: hidden;

        .pointer {
            display: block;
            width: 100%;
            height: 20px;
            text-align: left;
            @include translated(50%, 0, 0);
            @include transition(all 0.3s ease-in-out);

            .pointer-marker {
                display: inline-block;
                fill: $pastel-lighter-frost;
                margin-left: -25px;
            }
        }
    }

    .description {
        background-color: $pastel-lighter-frost;
        padding: 15px;
    }
}

.slider-wrapper-new {
    .options {
        @include flexbox(column, center);
        flex-wrap: wrap;
        overflow: hidden;

        .option {
            @include flexbox(row, center);
            flex-wrap: wrap;
            width: 100%;
            cursor: pointer;
            padding: 10px 5px;
            margin-bottom: 10px;
            border-radius: 5px;
            background-color: $primary-colour-10;

            input[type=radio] {
                -webkit-appearance: none;
                -moz-appearance: none;
                appearance: none;
                border-radius: 50%;
                width: 31px;
                height: 31px;
                background: $primary-white;
                border: 2px solid $primary-colour;
                transition: 0.2s all linear;
                outline: none;
                margin: 0 40px;
                display: block!important;
                position: relative;
                top: 4px;
                cursor: pointer;
            }

            input::after {
                content: "";
                background-color: $primary-white;
                color: $primary-white;
                width: 1px;
                height: 1px;
                top: 13px;
                left: 13px;
                position: relative;
                display: block;
                border-radius: 50%;
                transition: 0.2s all linear;
            }

            input:checked::after {
                background-color: $secondary-02;
                color: $secondary-02;
                width: 17px;
                height: 17px;
                top: 5px;
                left: 5px;
            }

            .prose {
                text-align: left;
                @include flex-grow(2);
                flex-basis: 200px;
                vertical-align: middle;

                label {
                    margin: 0;
                    display: block;
                    height: 14px;
                }

                .description {
                    display: none;
                    white-space: normal;
                    margin: 10px 0 0;

                    &.selected {
                        display: block;
                    }
                }
            }

            img {
                @include transform(scale(-1, 1));
                @include flex-grow(1);
            }
        }

        input[type=radio]:checked ~ label {
            img {
                opacity: 1;
                @include transform(scale(1.3));
            }
            img.greyscale {
                opacity: 0;
            }
        }
    }

    .pointer-track {
        display: block;
        width: 100%;
        height: 20px;
        overflow: hidden;

        .pointer {
            display: block;
            width: 100%;
            height: 20px;
            text-align: left;
            @include translated(50%, 0, 0);
            @include transition(all 0.3s ease-in-out);

            .pointer-marker {
                display: inline-block;
                fill: $pastel-lighter-frost;
                margin-left: -25px;
            }
        }
    }
}

.event-list {
    list-style: none;
    padding: 0;

    li {
        padding: 0;
        margin-bottom: 5px;

        &:before {
            @include inalign(middle);
            margin-right: 5px;
        }

        &[data-state=success] {
            color: $secondary-01;

            &:before {
                @include icon($icon-check);
            }
        }

        &[data-state=error] {
            color: $product-error;

            &:before {
                @include icon($icon-exclamation);
            }
        }
    }
}

.seanbox {
    background: $pastel-lighter-frost;
    padding: 20px;
    margin: 10px 0;
    border-radius: 5px;
    text-align: left;

    .title-bar {
        display: flex;
        align-items: flex-start;
        margin-bottom: 10px;

        .img {
            border-radius: 50%;
            height: 50px;
            width: 50px;
            display: inline-block;
        }

        .sean {
            background: center / contain no-repeat url("/static/images/veterinary/team/sean.jpg");
        }

        .samantha {
            background: center / contain no-repeat url("/static/images/veterinary/team/samantha.jpg");
        }

        .title {
            display: inline-block;
            vertical-align: middle;
            align-self: center;
            padding: 0 20px;

            .name {
                font-weight: bold;
                display: inline-block;
                height: 100%;
            }
        }
    }

    .description {
        display: none;
    }

    .selected {
        display: block;
    }
}

.tails-view-address-lookup-fuzzy {
    .panel {
        background-color: $pastel-lighter-frost;
        padding: 20px;
    }

    #toggle-mode {
        display: block;
        margin-top: 20px;
    }

    #search {
        width: 100%;
    }

    label {
        margin-bottom: 5px;
    }

    .summary {
        display: none;
        text-align: center;

        .first-line {
            font-weight: bold;
        }
    }

    .form-row {
        display: flex;

        > .form-group {
            margin-left: 20px;
        }

        .form-group:first-child {
            margin-left: 0;
        }
    }

    &[data-mode='manual'] {
        #toggle-mode:before {
            content: attr(data-manual);
        }

        .full-address {
            display: block;
        }

        .search {
            display: none;
        }
    }

    &[data-mode='auto'] {
        #toggle-mode:before {
            content: attr(data-auto);
        }

        .full-address {
            display: none;
        }

        &[data-state='populated'] {
            .search, #toggle-mode {
                display: none;
            }

            .summary {
                display: block;
            }
        }
    }
}

.pcaautocomplete {
    max-width: calc(100vw - 30px) !important;

    .pcaitem {
        white-space: normal !important;
    }
}

.radio-show-hide {
    text-align: left;

    input[type=radio] {
        display: none;
    }

    #yes-radio-button:checked ~ .radio-buttons .radio-toggle-button label[for=yes-radio-button],
    #no-radio-button:checked ~ .radio-buttons .radio-toggle-button label[for=no-radio-button] {
        @include radio_toggle_on;
    }

    .radio-show-hide-details {
        display: none;
    }

    #yes-radio-button:checked ~ .radio-show-hide-details {
        display: block;
    }

    .searcher {
        width: 100%;

        .search {
            border: 1px solid $navy-01;
            border-radius: 5px;
        }
    }

    .list {
        margin-top: 1rem;
        border: 1px solid $navy-01;
        border-radius: 5px;
        overflow: hidden;
    }
}

.collection-points-list {
    padding: 20px 0;

    h4 {
        text-align: center;
    }

    .single-point {
        margin: 25px 0;

        input[type=radio] {
            display: none;

            &:checked {
                ~ .point-details {
                    .opening-hours {
                        display: block;
                    }
                }

                ~ .collection-point {
                    label {
                        @include radio_toggle_on;

                        span {
                            font-weight: bold;
                        }
                    }
                }
            }
        }

        .opening-hours {
            display: none;
        }
    }

    .collection-point {
        margin: 17px 0;

        .radio-buttons {
            text-align: left;

            .radio-toggle-button {
                margin: 0;
            }
        }

        .radio-buttons input[type=radio]:checked ~ label span {
            font-weight: bold;
        }
    }
}

.point-details p {
    line-height: 1;
}

.tabs {
    @include flexbox(row, stretch, center);
    border-radius: 20px;
    margin-bottom: $spacing-xxs;

    .tab {
        flex: 1;
        cursor: pointer;
        background-color: $primary-white;
        @include flexbox(row, center, center);
        padding: $spacing-xxs;
        line-height: 1.5;
        font-size: $font-size-body;
        border-top: 1px solid $product-selected;
        border-bottom: 1px solid $product-selected;
        position: relative;
        z-index: 0;
        transition: background-color 0.3s ease, color 0.3s ease;

        &:first-child {
            border-left: 1px solid $product-selected;
            border-top-left-radius: 17px;
            border-bottom-left-radius: 17px;
        }

        &:last-child {
            border-right: 1px solid $product-selected;
            border-top-right-radius: 17px;
            border-bottom-right-radius: 17px;
        }

        &.active,
        &:hover,
        &:focus-visible,
        &:active {
            color: $primary-white;
        }

        &.active {
            background-color: $product-selected;
            font-weight: bold;
        }

        &:hover {
            background-color: $product-selected-darker;
        }

        &:focus-visible {
            background-color: $product-selected-darker;
            box-shadow: 0 0 10px 2px $secondary-03;
            z-index: 1;
            outline: 0;
        }

        &:active {
            background-color: $product-selected-lighter;
        }
    }
}

.order-tables {
    overflow: hidden;

    input[type=radio] {
        display: none;
    }

    .track {
        width: 200%;
        white-space: nowrap;
        @include transition(all 0.3s);
    }

    #show-first:checked {
        ~ .order-table.first {
            @include translated(0, 0, 0);
        }

        ~ .tabs .tab {
            &.first {
                background-color: $product-selected;
                color: $primary-white;
                font-weight: bold;
            }
        }
    }

    #show-next:checked {
        ~ .tabs .tab {
            &.next {
                background-color: $product-selected;
                color: $primary-white;
                font-weight: bold;
            }
        }

        ~ .track {
            @include translated(calc(-50% - 5px), 0, 0);
        }
    }

    .order-table {
        @include inalign(top);
        width: 50%;
        outline: none;
        white-space: normal;

        .first {
            .delivery-note-star {
                display: none;
            }
        }
    }

    .delivery-smallprint {
        white-space: normal;
        margin-top: 1.5em;
        font-size: $font-size-s;
        margin-right: 4px;
    }
}
